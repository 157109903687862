import React from "react"
import { graphql } from "gatsby"
import "../assets/styles/style.scss"
import { Helmet } from "react-helmet"

function parseQueryResult(data) {
    let pages = []

    data && data.allStoryblokEntry.edges.forEach((edge) => {
        const { node } = edge
        pages.push(node)
    })

    return { pages }
}

const DataLandingsPage = ({ data }) => {
    const { pages } = parseQueryResult(data)

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div>
                <table className="basic-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Full Slug</th>
                            <th>Published At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pages && pages.map((page) => {

                            const page_url = page.full_slug.replace("hult/", "https://www.hult.edu/")

                            if (page.published_at) {
                                return (
                                    <tr key={page.id}>
                                        <td>{page.name} <a href={"https://app.storyblok.com/legacy-v1/#!/me/spaces/45434/stories/0/0/" + page.id.replace("storyblokentry-", "").replace("-default", "")}>edit</a></td>
                                        <td><a href={page_url}>{page_url}</a></td>
                                        <td>{page.published_at}</td>
                                    </tr>
                                )
                            }
                            return
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DataLandingsPage

export const query = graphql`
  query {
    allStoryblokEntry(
        filter: {full_slug: {regex: "/hult/lp/"}, published_at: {ne: null}}
        sort: {fields: name}
    ) {
      edges {
        node {
          name
          published_at
          id
          full_slug
        }
      }
    }
  }
`
